@import 'Styles/Medborgarskolan/includes';

/**
*
* CardCalendar
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardCalendar.Base.module.scss';

.CardCalendar {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    background-color: $purple-20;
    border-radius: 8px;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: -1px;
        bottom: 0px;
        left: -1px;
        width: 8px;
        background: $blue;
        border-radius: 8px 0 0 8px;
        transition: all .3s;
    }

    &__Bg {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &__Container {
        padding: 16px 16px 24px 20px;

        @include media(sm) {
            padding: 24px 24px 40px 28px;
        }
    }

    &__DateContainer {
        position: relative;
        padding-right: 130px;
        margin-top: -8px;
        min-height: 60px;
        color: $blue;
        transition: color .3s;
        z-index: 1;

        @include media(md) {
            margin-top: -16px;
            margin-bottom: 40px;
        }
    }

    &__Date {
        line-height: normal;

        &--Day {
            margin-bottom: 2px;
            font-size: 5.6rem;
            font-weight: $bold;
        }

        &--Month {
            font-size: 1.4rem;
            font-weight: $bold;
            letter-spacing: 1px;
        }
    }

    &__Title {
        font-size: 2.6rem;
        line-height: 36px;
        color: $blue;

        @include media(md) {
            margin-bottom: 24px;
        }
    }

    &__Info {
        margin-bottom: 10px;

        &::before {
            content: '';
            flex: 0 0 auto;
            display: inline-block;
            position: relative;
            top: 1px;
            width: 20px;
            height: 20px;
            margin-right: 8px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }

        &--Null {
            display: none;
        }

        &--Location::before {
            background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/location--blue.svg');
        }

        &--Time::before {
            background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/time--blue.svg');
        }

        &--Empty {
            &::before {
                display: none;
            }
        }
    }

    &__Price {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: auto;
        padding: 8px 16px;
        font-size: 1.6rem;
        font-family: $font-heading;
        font-weight: $bold;
        line-height: 1.33;
        color: $blue;
        z-index: 0;

        @include media(sm) {
            min-width: 143px;
            padding: 15px 24px;
            font-size: 2rem;
            text-align: right;
        }

        @include media(xl) {
            padding: 15px 32px;
            font-size: 2.2rem;
            line-height: 1.39;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            display: block;
            width: 125px;
            height: 50px;
            border-top-right-radius: 8px;
            background: url('#{$basepath}img/bg-icons/medborgarskolan/new/price-bg-top--purple-20.svg')
                no-repeat right top $purple-10;
            z-index: -1;

            @include media(sm) {
                border-top-right-radius: 4px;
                transform: scaleX(1.3) scaleY(1.4);
                transform-origin: top right;
            }

            @include media(xl) {
                transform: scaleX(1.5) scaleY(1.6);
            }
        }
    }

    &__Icon {
        display: none;

        svg {
            fill: $blue;
        }
    }

    &__Alert {
        margin-top: auto;
        padding-left: 22px;
        padding-right: 16px;
        background-color: $yellow-20;
        border-radius: 0 0 8px 8px;
    }

    &__AlertWrapper {
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;

        @include media(sm) {
            padding-top: 12px;
            padding-bottom: 12px;
        }

        svg {
            width: 20px;
            height: 20px;
            flex: 0 0 auto;
            fill: $blue;
        }
    }

    &__AlertMessage {
        margin-left: 8px;
        font-size: 1.2rem;
        font-weight: $bold;
        color: $blue;
        line-height: 1.1;
    }
}
