@import 'Styles/Pahlmans/includes';

/**
*
* CardCalendar
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardCalendar.Base.module.scss';

.CardCalendar {
    $root: &;

    display: flex;
    flex-direction: column;
    justify-content: stretch;
    background-color: white;
    border: 1px solid palette(black, 10);

    &::after {
        content: '';
        display: block;
        width: 4px;
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        background: $primary-color;
    }

    &__Bg {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: $calendar-hover-color;
        opacity: 0;
        transition: opacity .3s ease-in-out;
        z-index: -1;

        #{$root}:hover & {
            opacity: 1;
        }
    }

    &__Container {
        padding: 30px 20px;
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;

        @include media(md) {
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 40px;
        }
    }

    &__DateContainer {
        color: $primary-color;
    }

    &__Date {
        line-height: 1;

        &--Day {
            margin-bottom: 10px;
            font-size: 4rem;
            font-weight: $bold;
            font-family: $font-heading;

            @include media(md) {
                font-size: 5.6rem;
            }
        }

        &--Month {
            font-size: 1.2rem;
            font-weight: $bold;
            letter-spacing: 1px;
        }
    }

    &__Title {

        @include media(md) {
            margin-bottom: 30px;
        }
    }

    &__Meta {
        flex: 1 0 auto;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

    &__Info {
        margin-bottom: 4px;
        flex: 0 0 auto;
    }

    &__Icon {

        svg {
            fill: $primary-color;

            #{$root}__Info--location {
                width: 14px;
                height: 17px;
            }
        }
    }

    &__Price,
    &__Alert {
        display: none;
    }
}
